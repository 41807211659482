<template>
  <div class="event-single-index">
    <portal to="topbar-left" v-if="event">
      <router-link :to="{ name: 'my-events' }" class="font-bold" :class="{
        'text-white': checkedInState,
        'text-brand-dark': !checkedInState,
      }" key="event-index-back">
        <icon-back class="w-6 h-6 text-brand-dark" />
      </router-link>
    </portal>

    <div class="relative z-auto max-w-screen-sm p-4 mx-auto">
      <div class="space-y-3">
        <div class="relative z-20 w-full p-3 overflow-hidden bg-white rounded-md shadow-sm" :class="{
          'text-brand-dark': isCheckedIn || !eventIsPast,
          'text-gray-400': eventIsPast && !isCheckedIn,
        }" @click="expandEvent">
          <button
            class="absolute top-0 right-0 inline-flex items-center justify-center w-10 h-10 group focus:outline-none"
            id="event-options-menu" aria-haspopup="true" :aria-expanded="formOpen" @click.stop="openEditForm"
            v-if="!checkedInState">
            <span
              class="flex items-center justify-center w-full h-full transition duration-150 ease-in-out rounded-bl-lg group-focus:bg-gray-200">
              <svg
                class="w-5 h-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500"
                viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
            </span>
          </button>

          <div class="flex flex-col p-1 space-y-4">
            <h1 class="pr-10 text-base font-bold leading-4 truncate">
              {{ event.name }}
            </h1>

            <event-item-date :date-from="event.date_from" :date-until="event.date_until" :event-is-past="eventIsPast" />

            <transition name="fadeDown">
              <div v-if="itemExpanded" class="space-y-4">
                <event-location v-if="!!event.location" :location="event.location" :event-is-past="eventIsPast" />

                <event-item-notes v-if="event.notes" :notes="event.notes" :event-is-past="eventIsPast" />
              </div>
            </transition>
          </div>
        </div>

        <div
          class="flex flex-col justify-between max-w-full space-x-0 space-y-4 truncate 2xs:flex-row 2xs:space-y-0 2xs:space-x-4 justify-items-stretch">
          <router-link :to="{ name: 'my-event-checklist', params: { uuid: event.uuid } }"
            class="flex flex-col items-center justify-center w-full h-32 px-2 py-3 bg-white rounded-md focus:outline-none focus:shadow-outline-gray hover:shadow">
            <div class="flex items-center justify-center flex-1 flex-shrink-0">
              <icon-checklist class="w-12 h-12 text-center fill-current text-brand-darkblue" />
            </div>
            <div class="flex flex-col items-start justify-around truncate">
              <span class="text-sm font-bold truncate text-brand-dark">{{ $t("event.checklist") }}</span>
            </div>
          </router-link>

          <t-button variant="event-button" :disabled="!isCheckedIn" @click="navigateToNotebook"
            class="flex flex-col items-center justify-center w-full h-32 px-2 py-3 bg-white rounded-md focus:outline-none focus:shadow-outline-gray hover:shadow">
            <div class="flex items-center justify-center flex-1 flex-shrink-0">
              <icon-notebook class="w-12 h-12 text-center fill-current text-brand-darkblue" />
            </div>
            <div class="flex flex-col items-start justify-around truncate">
              <span class="text-sm font-bold truncate text-brand-dark">{{ $t("event.notebook") }}</span>
            </div>
          </t-button>
        </div>

        <div v-if="!currentEvent">
          <router-link :to="{
            name: 'my-event-preparation-tool',
            params: { uuid: event.uuid },
          }"
            class="relative flex flex-row items-center justify-between w-full px-2 py-3 bg-white rounded-md focus:outline-none focus:shadow-outline-gray hover:shadow">
            <div class="flex items-center justify-center flex-shrink-0 w-9">
              <icon-lightbulb class="w-6 h-6 fill-current text-brand-darkblue" />
            </div>
            <div class="flex flex-col items-start justify-around flex-auto truncate">
              <span class="block text-sm font-bold text-brand-dark">{{ $t("event.preparation-tool") }}</span>
            </div>
            <div class="flex items-end justify-center flex-shrink-0">
              <svg viewBox="0 0 12 22" class="w-3 h-3 font-black mx-at text-brand-dark text-7xl">
                <g fill="none" fill-rule="evenodd" transform="translate(-8 -1)">
                  <path fill="currentColor"
                    d="M8.412 22.089c.547.548 1.43.548 1.977 0l9.284-9.3a1.116 1.116 0 000-1.578l-9.284-9.3a1.4 1.4 0 00-1.977 1.98l8.088 8.115-8.1 8.113a1.397 1.397 0 00.012 1.97z" />
                </g>
              </svg>
            </div>
            <div v-if="event.preparation_tool"
              class="h-6 w-6 shadow-md bg-white grid place-items-center absolute -top-2.5 -right-2.5 rounded-full">
              <icon-check class="w-3 h-3 fill-current text-brand-darkblue"></icon-check>
            </div>
          </router-link>
        </div>

        <template v-if="(checkedInState || eventIsPast) && event.assignment">
          <tip-block :tip="event.assignment.tip" :dark="checkedInState" :used="event.assignment.tip_used" />
        </template>
      </div>

      <template v-if="checkedInState || (!eventIsPast && eventIsOngoing)">

        <div v-if="checkedInState" class="py-6 text-center">
          <div v-if="eventProposedCheckout">
            <!-- <pre>{{ JSON.stringify(eventProposedCheckout) }}</pre> -->
            <countdown :time="eventProposedCheckout - new Date()" v-if="eventProposedCheckout - new Date() >= 0">
              <template slot-scope="props">
                <p class="text-sm font-semibold">{{ $t('event.checkout-proposed-time--before') }}</p>

                <div class="flex flex-row items-baseline justify-center space-x-2 text-sm">
                  <span v-if="props.days">{{ props.days }} {{ $t('timings.days') }}</span>
                  <span v-if="props.hours">{{ props.hours }} {{ $t('timings.hours') }}</span>
                  <span v-if="props.minutes || props.hours">{{ props.minutes }} {{ $t('timings.minutes') }}</span>
                </div>

              </template>
            </countdown>
            <div v-else>
              <p class="text-sm">{{ $t('event.checkout-proposed-time--passed') }}</p>
              <pre>{{ $d(eventProposedCheckout, 'long') }}</pre>
            </div>
            <p class="mt-2 text-sm font-medium">{{ $t('event.checkout-proposed-time--append') }}</p>

          </div>
        </div>

        <div class="py-12 space-y-16">
          <template v-if="drawDraggableVerificationSlider">
            <draggable-verification-slider :status="!!checkedInState" :inactive-text="$t('event.checked-in--false')"
              :active-text="$t('event.checked-in--true')" :disabled="checkedIn === undefined || isOffline"
              :both-ways="true" :wrapper-padding="4" @status-changed="handeVerificationSliderChanged"
              class="w-full h-20 max-w-xs mx-auto" :key="`checkin-verification-slider`"
              ref="checkin-verification-slider" />
          </template>

          <div class="flex flex-row items-baseline select-none justify-evenly" v-if="checkedInState">
            <div class="flex flex-col items-center space-y-2">
              <t-button :to="{ name: 'info-drugcombos' }" variant="circle"
                class="grid w-20 h-20 rounded-full place-items-center bg-brand-lightblue">
                <icon-drugcombo class="w-10 h-10" />
              </t-button>
            </div>

            <div class="flex flex-col items-center space-y-2">
              <t-button :to="{ name: 'info-emergency' }" variant="circle"
                class="grid w-20 h-20 border rounded-full place-items-center border-brand-lightblue hover:bg-brand-lightblue">
                <icon-emergency-help class="w-10 h-10 transition fill-current text-brand-lightblue hover:text-white" />
              </t-button>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!checkedInState && eventIsPast">
        <div class="pb-10 mt-6 space-y-3">
          <h3 class="text-xl font-bold">
            {{ $t("event.title--mood-overview") }}
          </h3>
          <div class="p-3 m-0 space-y-2 bg-white rounded-lg shadow-sm">
            <div class="flex flex-col items-center justify-around space-y-6 divide-y 2xs:divide-y-0 2xs:flex-row 2xs:space-y-0">
              
              <div class="flex flex-col items-center justify-between w-full h-full space-y-2">
                <div class="flex-shrink-0 text-sm font-semibold text-gray-600">{{ $t(`event.moods--before`) }}:</div>
                
                <div class="flex flex-col items-center flex-1">
                  <mood-icon class="xs:w-4/5" :value="event.after.checkin" :shadowColor="`#DDDDDD`" />
                  <div class="flex-shrink-0 text-sm font-medium text-brand-dark">
                    {{ event.after.checkin ? $t(`moods.${event.after.checkin}`) : $t("unknown") }}
                  </div>
                </div>

              </div>
              
              <div class="flex flex-col items-center justify-between w-full h-full space-y-2">
                <div class="flex-shrink-0 text-sm font-semibold text-gray-600">{{ $t(`event.moods--after`) }}:</div>
               
                <div class="flex flex-col items-center flex-1">
                  <mood-icon class="xs:w-4/5" :value="event.after.checkout" :shadowColor="`#DDDDDD`" />
                  <div class="flex-shrink-0 text-sm font-medium text-brand-dark">
                    {{ event.after.checkout ? $t(`moods.${event.after.checkout}`) : $t("unknown") }}
                  </div>
                </div>

              </div>
              
              <div class="flex flex-col items-center justify-between w-full h-full space-y-2">
                <div class="flex-shrink-0 text-sm font-semibold text-gray-600">{{ $t(`event.moods--after-two-days`) }}:</div>

                <div class="flex flex-col items-center flex-1">
                  <mood-icon class="xs:w-4/5" :value="event.after.after" :shadowColor="`#DDDDDD`" />
                  <div class="flex-shrink-0 text-sm font-medium text-brand-dark">
                    {{ event.after.after ? $t(`moods.${event.after.after}`) : $t("unknown") }}
                  </div>
              </div>
            </div>
          </div>
        </div>

        <mood-modal :show="showAfterModal" @close="handleClosingAfterModal" key="after-check-mood-modal">
          <mood-after-form :event="event" @after-cancel="handleClosingAfterModal"
            @after-success="handleMoodAfterSuccess" @event-updated="eventUpdated" />
        </mood-modal>

        <mood-modal :show="showAfterTipModal" @close="handleClosingAfterTipModal" v-if="event.after && event.assignment"
          key="after-tip-mood-modal">
          <tip-after-form :event="event.uuid" :assignment="event.assignment" :mood="event.after.after || null"
            v-on:tipafter-cancel="handleClosingAfterTipModal" v-on:tipafter-success="handleMoodAfterTipSuccess"
            v-on:event-updated="eventUpdated" />
        </mood-modal>
      </template>
    </div>

    <checkin-modal :show="showCheckinModal" @close="handleClosingModal">
      <checkin-form :event="event.uuid" v-on:checkin-cancel="handleClosingModal"
        v-on:checkin-success="handleCheckinSuccess" v-on:event-updated="eventUpdated" v-if="!checkedInState" />
      <checkout-form :event="event.uuid" v-on:checkout-cancel="handleClosingModal"
        v-on:checkout-success="handleCheckoutSuccess" v-on:event-updated="eventUpdated" v-else-if="checkedInState" />
    </checkin-modal>

    <popup-pane v-if="formOpen" :title="$t('event.title--edit-event')" @close="formOpen = false">
      <event-form :event="event" @success="handleFormSuccess" v-if="formOpen" />
    </popup-pane>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_REQUEST } from "actions/user";
import draggableVerificationSlider from "components/DraggableVerificationSlider";
import CheckinModal from "components/Event/CheckinModal";
import CheckinForm from "components/Event/CheckinForm";
import CheckoutForm from "components/Event/CheckoutForm";
import MoodModal from "components/Event/MoodModal";
import MoodAfterForm from "components/Event/MoodAfterForm";
import TipAfterForm from "components/Event/TipAfterForm";
import EventLocation from "components/Event/Location";
import EventItemNotes from "components/Event/ItemNotes";
import EventItemDate from "components/Event/ItemDate";
import IconBack from "assets/icons/back.svg";
import IconNotebook from "assets/icons/paper-clock.svg";
import IconChecklist from "assets/icons/clipboard.svg";
import IconLightbulb from "assets/icons/lightbulb.svg";
import IconDrugcombo from "assets/icons/drug-pills.svg";
import IconEmergencyHelp from "assets/icons/emergency-help.svg";
import IconCheck from "assets/icons/check.svg";
import PopupPane from "components/PopupPane";
import EventForm from "components/Event/Form";
import MoodIcon from "components/MoodIcon";
import TipBlock from "components/TipBlock";

export default {
  name: "event-single-index",
  components: {
    draggableVerificationSlider,
    CheckinModal,
    CheckinForm,
    CheckoutForm,
    MoodModal,
    MoodAfterForm,
    TipAfterForm,
    IconChecklist,
    IconEmergencyHelp,
    IconNotebook,
    IconBack,
    IconCheck,
    PopupPane,
    EventForm,
    MoodIcon,
    TipBlock,
    EventLocation,
    EventItemNotes,
    EventItemDate,
    IconLightbulb,
    IconDrugcombo,
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: undefined,
    },
    autoCheckin: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    autoOpenForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loadingCheckinState: false,
    showCheckinModal: false,
    showAfterModal: false,
    showAfterTipModal: false,
    checkedIn: undefined,
    formOpen: false,
    buddyModalOpen: false,
    tipShown: false,
    drawDraggableVerificationSlider: true,
    itemExpanded: false,
  }),
  computed: {
    ...mapGetters(["currentEvent", "isCheckedIn", "getBuddies", "getUsername"]),
    emergencyNumber() {
      return "+32-112";
    },
    eventIsPast() {
      return this.now > this.eventUntil;
    },
    eventIsOngoing() {
      return this.now >= this.eventFrom && this.now <= this.eventUntil;
    },
    checkedInState() {
      return this.isCheckedIn && this.currentEvent == this.event.uuid;
    },
    eventFrom() {
      return new Date(new Date(this.event.date_from).setHours(0, 0, 0, 0));
    },
    eventUntil() {
      if (this.event.date_until) {
        return new Date(new Date(this.event.date_until).setHours(23, 23, 23, 59));
      }
      return new Date(new Date(this.event.date_from).setHours(23, 23, 23, 59));
    },
    eventProposedCheckout() {
      if (
        this.event.preparation_tool
        && this.event.preparation_tool.preptool_question_two
        && this.event.preparation_tool.preptool_question_two.answer
      ) {
        return new Date(this.event.preparation_tool.preptool_question_two.answer);
      }
      return null; //this.eventUntil;
    },
    now() {
      return new Date();
    },
  },
  methods: {
    navigateToNotebook() {
      this.$router.push({ name: "my-event-notebook", params: { uuid: this.event.uuid } });
    },
    openEditForm() {
      if (this.checkedInState) return;
      this.formOpen = true;
    },

    autoCheckinEvent() {
      this.$nextTick(() => {
        if (!this.checkedIn) {
          this.showCheckinModal = true;
        }
      });
    },

    handleFormSuccess() {
      this.formOpen = false;
      this.$emit("event-updated");
    },
    eventUpdated(d) {
      this.$emit("event-updated", d);
      this.$store.dispatch(USER_REQUEST);
      this.$forceUpdate();
    },
    changeStatus(state) {
      this.checkedIn = state;
    },
    handleClosingModal() {
      this.showCheckinModal = false;
      this.$store.dispatch(USER_REQUEST);
      this.changeStatus(this.checkedInState);
      this.redrawVerificationSlider();
    },
    closeTipModal() {
      this.tipShown = false;
    },
    openTipModal() {
      this.tipShown = true;
    },
    handeVerificationSliderChanged(state) {
      if (this.showCheckinModal) {
        this.checkedInState(this.checkedInState);
        return;
      }

      if (!this.showCheckinModal) {
        this.showCheckinModal = state != this.checkedIn;
        this.changeStatus(this.checkedInState);
      } else if (this.showCheckinModal) {
        this.showCheckinModal = false;
        this.changeStatus(this.checkedInState);
      }
    },

    redrawVerificationSlider() {
      this.drawDraggableVerificationSlider = false;

      this.$nextTick(() => {
        this.drawDraggableVerificationSlider = true;
      });
    },

    handleCheckinSuccess() {
      this.showCheckinModal = false;

      this.changeStatus(true);
    },
    handleCheckoutSuccess() {
      this.showCheckinModal = false;
      this.changeStatus(false);
    },
    handleOpenAfterModalTrigger() {
      if (this.event.after.after) {
        if (!confirm("Are you sure you want to change your after mood?")) {
          return;
        }
      }
      if (this.showAfterModal) return;
      this.showAfterModal = true;
    },
    handleClosingAfterModal() {
      this.showAfterModal = false;
    },
    handleMoodAfterSuccess() {
      // check for tip, then show the question modal for that.
      if (this.event.assignment) {
        this.showAfterTipModal = true;
      }
    },
    handleClosingAfterTipModal() {
      this.showAfterTipModal = false;
    },
    handleMoodAfterTipSuccess() {
      this.showAfterTipModal = false;
    },

    expandEvent() {
      if (this.event.location || this.event.notes) {
        this.itemExpanded = !this.itemExpanded;
      }
    },

    handleAutoOpenForm() {
      if (this.autoOpenForm) {
        this.$nextTick(() => {
          this.showAfterModal = true;
        });
      }
    },
  },
  mounted() {
    this.checkedIn = this.checkedInState;

    if (this.autoCheckin) {
      this.autoCheckinEvent();
    }
    this.handleAutoOpenForm();
  },
  watch: {
    checkedInState(state) {
      this.changeStatus(state);
    },
  },
};
</script>

<template>
<transition
    enter-active-class="transition duration-500 ease-in-out"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-500 ease-in-out"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
>
    <div
        v-show="shown"
        class="fixed inset-0 z-99 flex items-center justify-center max-h-screen p-4 overflow-hidden bg-brand-dark-75 backdrop backdrop-blur-5"
        v-on:click.self="close"
        v-cloak
    >
        <div 
            role="dialog"
            aria-modal="true"
            :aria-expanded="shown"
            class="relative w-full z-100 max-w-sm max-h-full overflow-y-auto text-white shadow-xl min-w-min sm:m-6 rounded-xl bg-brand-dark margin-mobile"
        >
            <button
                class="absolute top-0 right-0 inline-flex items-center justify-center w-10 h-10 group focus:outline-none"
                v-on:click.prevent="close"
            >
                <span class="flex items-center justify-center w-full h-full transition duration-150 ease-in-out rounded-bl-lg group-focus:bg-gray-200">
                    <icon-close class="w-5 h-5 text-gray-100 transition duration-150 ease-in-out group-hover:text-white group-focus:text-white" />
                </span>
            </button>

            <slot @finished="handleSlotFinished" />
        </div>
    </div>
</transition>
</template>

<script>
import IconClose from 'assets/icons/close.svg'

export default {
    name: 'mood-modal',
    components: {
        IconClose
    },
    props: {
        show: Boolean
    },
    data: () => ({
        shown: false
    }),
    watch: {
        show(newState){
            this.shown = newState
        }
    },
    methods: {
        close() {
            this.shown = false
            this.$emit('close')
        },
        handleSlotFinished(){
            this.close()
        }
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"dvs_wrapper backdrop-filter",class:{
    'backdrop-blur-3 inactive bg-white-90 shadow-checkin-false backdrop-filter':
      !_vm.sliderStatus,
    'active backdrop-blur-3 bg-transparent shadow-checkin-true backdrop-filter':
      !!_vm.sliderStatus,
    'disabled opacity-75': !!_vm.disabled,
  },style:(_vm.wrapperStyle)},[_c('div',{ref:"slider",staticClass:"dvs_slider",style:(_vm.sliderStyle),on:{"mousemove":_vm.dragMoving,"mouseup":_vm.dragFinish,"touchmove":_vm.dragMoving,"touchend":_vm.dragFinish}},[(_vm.message)?_c('div',{ref:"message",staticClass:"dvs_label transition duration-300",style:(_vm.labelStyle)},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('div',{ref:"handler",staticClass:"dvs_handler focus:outline-none relative flex items-center justify-center rounded-full border-4 shadow-circle-inner transition duration-300 focus:ring-2 focus:ring-inset",class:{
        'border-navbar-active bg-navbar-active': !_vm.sliderStatus,
        'border-brand-lightblue bg-brand-lightblue': !!_vm.sliderStatus,
      },style:(_vm.handlerStyle),on:{"mousedown":_vm.dragStart,"touchstart":_vm.dragStart,"mouseup":_vm.dragFinish,"touchend":_vm.dragFinish}},[(!_vm.sliderStatus)?_c('icon-arrow-right',{staticClass:"h-8 w-8"}):_c('icon-arrow-left',{staticClass:"h-8 w-8"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
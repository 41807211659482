<template>
  <div class="space-y-2 p-6">
    <div class="select-none">
      <h3 class="mb-2 text-xl font-bold text-white">
        {{ $t("event.mood-modal-after--title") }}
      </h3>
      <p class="text-sm" v-if="step === getRandomPreptoolValues.length">
        {{ $t("event.mood-modal-after--subtitle") }}
      </p>
    </div>

    <div v-if="step < getRandomPreptoolValues.length">
      <div v-if="step === 0">
        <p
          class="mt-4"
          :class="{
            'mb-2': shouldNotShowAnswer,
          }"
        >
          {{
            $t(
              `preparation-tool.answers.${getRandomPreptoolValues[step].question_after}`
            )
          }}
        </p>
        <div class="relative my-4 flex space-x-2" v-if="!shouldNotShowAnswer">
          <div class="relative">
            <transition name="fade">
              <p
                class="absolute bottom-full left-full rounded-md bg-white p-2 text-sm text-black"
                v-if="infoTextOneOpen"
              >
                {{ $t("preparation-tool.your_answer") }}
              </p>
            </transition>
            <icon-info
              @click="infoTextOneOpen = !infoTextOneOpen"
              class="h-4 w-4 fill-current text-white"
            />
          </div>

          <p class="text-xs opacity-75">
            {{
              typeof getRandomPreptoolValues[step].answer === "object"
                ? getRandomPreptoolValues[step].answer.join(", ")
                : !isNaN(Date.parse(getRandomPreptoolValues[step].answer))
                ? getRandomPreptoolValues[step].answer.split("T")[1]
                : getRandomPreptoolValues[step].answer
            }}
          </p>
        </div>
        <t-textarea v-model="preptoolAfterQuestionOne" />
      </div>

      <div v-if="getRandomPreptoolValues.length > 1 && step === 1">
        <p class="mt-4" :class="{ 'mb-2': shouldNotShowAnswer }">
          {{
            $t(
              `preparation-tool.answers.${getRandomPreptoolValues[step].question_after}`
            )
          }}
        </p>

        <div class="relative my-4 flex space-x-2" v-if="!shouldNotShowAnswer">
          <div class="relative">
            <transition name="fade">
              <p
                class="absolute bottom-full left-full rounded-md bg-white p-2 text-sm text-black"
                v-if="infoTextOneOpen"
              >
                {{ $t("preparation-tool.your_answer") }}
              </p>
            </transition>
            <icon-info
              @click="infoTextOneOpen = !infoTextOneOpen"
              class="h-4 w-4 fill-current text-white"
            />
          </div>

          <p class="text-xs opacity-75">
            {{
              typeof getRandomPreptoolValues[step].answer === "object"
                ? getRandomPreptoolValues[step].answer.join(", ")
                : !isNaN(Date.parse(getRandomPreptoolValues[step].answer))
                ? getRandomPreptoolValues[step].answer.split("T")[1]
                : getRandomPreptoolValues[step].answer
            }}
          </p>
        </div>

        <t-textarea v-model="preptoolAfterQuestionTwo" />
      </div>
    </div>

    <form
      id="mood-after-form"
      class="space-y-4"
      role="form"
      @submit.prevent="handleUpdateAfterMood"
      v-else
    >
      <mood-selector :mood="mood" @update="updateMood" />
      <t-input
        v-model="comment"
        variant="dark"
        :placeholder="$t('event.form.placeholder--note-to-self')"
      />
    </form>

    <div class="space-y-2">
      <t-button variant="white" v-if="step > 0" @click="step--">{{
        $t("action.previous")
      }}</t-button>
      <t-button variant="primary" @click="handleNextButtonClick">{{
        step === getRandomPreptoolValues.length
          ? $t("action.save")
          : $t("action.next")
      }}</t-button>
    </div>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import MoodSelector from "components/MoodSelector";
import { sampleSize as _sampleSize } from "lodash";
import IconInfo from "assets/icons/info.svg";

const EventRepository = Repository.get("my-events");

export default {
  name: "event-mood-after-form",

  components: {
    MoodSelector,
    IconInfo,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      mood: "neutral",
      comment: "",
      step: 0,
      preptoolAfterQuestionOne: "",
      preptoolAfterQuestionTwo: "",
      infoTextOneOpen: false,
      infoTextTwoOpen: false,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.loading = false;
      this.mood = "neutral";
      this.comment = "";
      if (!this.event) {
        this.$emit("after-cancel");
      }
    },

    cancel() {
      this.$emit("after-cancel");
    },

    close() {
      if (this.loading) return;
      this.$emit("after-cancel");
    },

    closePositive() {
      this.$emit("after-success", this.event.uuid);
    },

    updateMood(mood) {
      this.mood = mood;
    },

    handleNextButtonClick() {
      if (this.step === this.getRandomPreptoolValues.length) {
        this.handleUpdateAfterMood();
        return;
      }
      this.step++;
    },

    handleUpdateAfterMood() {
      this.loading = true;
      let preptoolAnswers = [];

      if (this.getRandomPreptoolValues[0]) {
        preptoolAnswers = [
          ...preptoolAnswers,
          {
            question: this.$t(
              `preparation-tool.answers.${this.getRandomPreptoolValues[0].question_after}`
            ),
            answer: this.preptoolAfterQuestionOne,
          },
        ];
      }

      if (this.getRandomPreptoolValues[1]) {
        preptoolAnswers = [
          ...preptoolAnswers,
          {
            question: this.$t(
              `preparation-tool.answers.${this.getRandomPreptoolValues[1].question_after}`
            ),
            answer: this.preptoolAfterQuestionTwo,
          },
        ];
      }

      if (!this.preptoolAfterQuestionOne && !this.preptoolAfterQuestionTwo) {
        preptoolAnswers = null;
      }

      EventRepository.checkAfter(this.event.uuid, {
        mood: this.mood,
        note: this.comment,
        preptoolAnswers,
      })
        .then((r) => {
          this.$emit("event-updated", r.data.event);
          this.closePositive();
        })
        .catch((error) => {
          console.log("after failed:", error.response);
        })
        .then(() => {
          this.loading = false;
          this.close();
        });
    },
  },

  computed: {
    getRandomPreptoolValues() {
      if (!this.event.preparation_tool) return [];
      const keys = Object.keys(this.event.preparation_tool);
      const preptoolArray = keys.filter(
        (key) =>
          !!this.event.preparation_tool[key].answer &&
          !!this.event.preparation_tool[key].question_after
      );
      const values = _sampleSize(preptoolArray, 2).sort((a, b) => a - b);

      return values.map((value) => ({
        question_after: this.event.preparation_tool[value].question_after,
        answer: this.event.preparation_tool[value].answer,
      }));
    },

    shouldNotShowAnswer() {
      return [
        this.$t("answers.yes"),
        this.$t("answers.no"),
        this.$t("answers.does_not_apply"),
      ].includes(this.getRandomPreptoolValues[this.step].answer);
    },
  },
};
</script>

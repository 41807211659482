<template>
  <div class="flex flex-row space-x-2">
    <div class="flex items-start justify-center flex-shrink-0 w-5">
      <svg
        viewBox="0 0 18 18"
        class="w-4 h-4 font-black text-center"
        :class="{
          'text-brand-lightblue': isCheckedIn || !eventIsPast,
          'text-gray-400': eventIsPast && !isCheckedIn
        }"
      >
        <g fill="none" fill-rule="evenodd" transform="translate(-2 -4.5)">
          <path
            fill="currentColor"
            fill-rule="nonzero"
            d="M19.227 6.22L12.976 21.17c-.346.827-1.534.813-1.853-.029l-2.107-5.444a1.035 1.035 0 00-.573-.573L3 13.016c-.835-.326-.856-1.507-.029-1.853l14.949-6.25c.82-.354 1.654.48 1.308 1.307z"
          />
        </g>
      </svg>
    </div>
    <div class="flex-1 min-w-0 text-sm font-semibold leading-4 truncate">
      {{ location }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Date",

  props: {
    location: {
      type: String
    },
    eventIsPast: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters(["isCheckedIn"])
  }
};
</script>

<style scoped></style>

<template>
  <div :class="['p-6 space-y-2', mood ? 'bg-mood-' + mood : 'bg-transparent']">
    <div
      :class="[
        'text-center select-none',
        mood ? 'text-mood-text-0' : 'text-white'
      ]"
    >
      <h3 class="text-xl font-bold">
        {{ $t("event.mood-modal-tipcheck--title") }}
      </h3>
      <p class="text-sm">{{ $t("event.mood-modal-tipcheck--subtitle") }}</p>
    </div>
    <form
      class="space-y-4"
      role="form"
      v-on:submit.prevent="handleUpdateAfterTip"
    >
      <!-- <mood-selector :mood="mood" @update="updateMood" /> -->
      <div
        class="relative z-10 flex items-center justify-center w-48 h-48 mx-auto"
      >
        <mood-icon v-bind:value="mood" class="w-full h-full" />
      </div>

      <p
        :class="[
          'max-w-xs mx-auto text-sm font-medium text-center',
          mood ? 'text-mood-text-1' : 'text-white'
        ]"
      >
        {{ $t("event.mood-modal-tipcheck--question") }}
      </p>

      <tip-block :tip="assignment.tip" :dark="false" />

      <div class="flex flex-row justify-around space-x-3">
        <div class="flex-1">
          <t-button
            type="submit"
            variant="white"
            class="block w-full"
            :disabled="loading"
            @click="answer = true"
            >{{ $t("action.yes") }}</t-button
          >
        </div>
        <div class="flex-1">
          <t-button
            type="submit"
            class="block w-full"
            :disabled="loading"
            @click="answer = false"
            >{{ $t("action.no") }}</t-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
const EventRepository = Repository.get("my-events");
import TipBlock from "components/TipBlock";
import MoodIcon from "components/MoodIcon";

export default {
  name: "event-mood-after-form",
  components: {
    MoodIcon,
    TipBlock
  },
  props: {
    event: {
      type: String,
      required: true
    },
    assignment: {
      type: Object,
      required: true
    },
    mood: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      answer: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = false;
      if (!this.event) {
        this.$emit("tipafter-cancel");
      }
    },
    cancel() {
      this.$emit("tipafter-cancel");
    },
    close() {
      if (this.loading) return;
      this.$emit("tipafter-cancel");
    },
    closePositive() {
      this.$emit("tipafter-success", this.event);
    },
    handleUpdateAfterTip() {
      this.loading = true;
      EventRepository.setTipUsefulness(
        this.event,
        this.assignment.id,
        this.answer
      )
        .then(() => {
          this.$emit("event-updated");
          this.closePositive();
        })
        .catch(error => {
          console.log("after failed:", error.response);
        })
        .then(() => {
          this.loading = false;
          this.close();
        });
    }
  }
};
</script>

<template>
  <div class="flex flex-row space-x-2" v-if="notes">
    <div class="flex items-start justify-center flex-shrink-0 w-5">
      <icon-event-note
        class="w-4 h-4 font-black text-center"
        :class="{
          'text-brand-lightblue': isCheckedIn || !eventIsPast,
          'text-gray-400': eventIsPast && !isCheckedIn
        }"
      />
    </div>
    <div class="flex-1 inline-block p-0 text-sm leading-4">
      {{ notes }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconEventNote from "assets/icons/event-notes.svg";

export default {
  name: "ItemNotes",
  components: {
    IconEventNote
  },
  props: {
    notes: {
      type: String
    },
    eventIsPast: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters(["isCheckedIn"])
  }
};
</script>

<style scoped></style>

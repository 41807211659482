<template>
  <div
    ref="wrapper"
    class="dvs_wrapper backdrop-filter"
    :style="wrapperStyle"
    :class="{
      'backdrop-blur-3 inactive bg-white-90 shadow-checkin-false backdrop-filter':
        !sliderStatus,
      'active backdrop-blur-3 bg-transparent shadow-checkin-true backdrop-filter':
        !!sliderStatus,
      'disabled opacity-75': !!disabled,
    }"
  >
    <div
      ref="slider"
      class="dvs_slider"
      :style="sliderStyle"
      @mousemove="dragMoving"
      @mouseup="dragFinish"
      @touchmove="dragMoving"
      @touchend="dragFinish"
    >
      <div
        ref="message"
        class="dvs_label transition duration-300"
        :style="labelStyle"
        v-if="message"
      >
        {{ message }}
      </div>
      <div
        ref="handler"
        class="dvs_handler focus:outline-none relative flex items-center justify-center rounded-full border-4 shadow-circle-inner transition duration-300 focus:ring-2 focus:ring-inset"
        :class="{
          'border-navbar-active bg-navbar-active': !sliderStatus,
          'border-brand-lightblue bg-brand-lightblue': !!sliderStatus,
        }"
        :style="handlerStyle"
        @mousedown="dragStart"
        @touchstart="dragStart"
        @mouseup="dragFinish"
        @touchend="dragFinish"
      >
        <icon-arrow-right class="h-8 w-8" v-if="!sliderStatus" />
        <icon-arrow-left class="h-8 w-8" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowRight from "assets/icons/chevron-right.svg";
import IconArrowLeft from "assets/icons/chevron-left.svg";
export default {
  name: "DraggableVerificationSlider",
  components: {
    IconArrowRight,
    IconArrowLeft,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    bothWays: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
    inactiveText: {
      type: String,
      default: "",
    },
    activeText: {
      type: String,
      default: "",
    },
    wrapperPadding: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    wrapperStyle: function () {
      return {
        padding: this.wrapperPadding + "px",
      };
    },
    sliderStyle: function () {
      return {
        lineHeight: this.sliderHeight + "px",
      };
    },
    labelStyle: function () {
      return {
        opacity: this.isMoving ? 0 : 1,
        paddingLeft: (!this.sliderStatus ? this.handlerWidth : 0) + "px",
        paddingRight: (this.sliderStatus ? this.handlerWidth : 0) + "px",
      };
    },
    handlerStyle: function () {
      return {
        top: 0 /* (this.sliderHeight - this.handlerHeight / 2) */ + "px",
        left: this.left + "px",
        width: this.handlerWidth + "px",
        height: this.handlerHeight + "px",
      };
    },
    message: function () {
      return this.sliderStatus
        ? this.activeText || null
        : this.inactiveText || null;
    },
    sliderWidth: function () {
      if (!this.isMounted) return;
      return this.proportions.slider.width;
    },
    sliderHeight: function () {
      if (!this.isMounted) return;
      return this.proportions.slider.height;
    },
    handlerWidth: function () {
      if (!this.isMounted) return;
      return this.proportions.handler.width; // - Math.round(this.wrapperPadding ? this.wrapperPadding/2 : 0)
    },
    handlerHeight: function () {
      if (!this.isMounted) return;
      return this.proportions.handler.height; // - Math.round(this.wrapperPadding ? this.wrapperPadding/2 : 0)
    },
  },
  data() {
    return {
      sliderStatus: false,
      isMounted: false,
      isMoving: false,
      positionCache: 0,
      left: 0,
      proportions: null,
    };
  },
  mounted: function () {
    this.init();
    this.isMounted = true;
    this.sliderStatus = this.status;
  },
  created() {
    window.addEventListener("resize", this.calculateProportions);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateProportions);
  },
  methods: {
    init: function () {
      this.calculateProportions();
    },
    calculateProportions: function () {
      this.proportions = {
        slider: {
          width: this.$refs.slider.offsetWidth,
          height: this.$refs.slider.offsetHeight,
        },
        handler: {
          width: this.$refs.slider.offsetHeight, //+ Math.round(this.wrapperPadding ? this.wrapperPadding/2 : 0),
          height:
            this.$refs.slider.offsetHeight -
            Math.round(this.wrapperPadding ? this.wrapperPadding / 2 : 0),
        },
      };
    },
    dragStart: function (e) {
      if (this.disabled) {
        return;
      }
      if (this.bothWays || !this.sliderStatus) {
        this.isMoving = true;
        var handler = this.$refs.handler;
        this.positionCache =
          (e.pageX || e.touches[0].pageX) -
          parseInt(handler.style.left.replace("px", ""), 10);
      }
    },
    dragMoving: function (e) {
      if (this.disabled || !this.isMoving) {
        return;
      }
      var _x = (e.pageX || e.touches[0].pageX) - this.positionCache;
      if (_x <= 0) {
        this.switchStatus(false);
      } else if (_x > 0 && _x <= this.sliderWidth - this.handlerWidth) {
        this.left = _x;
      } else if (_x > this.sliderWidth - this.handlerWidth) {
        this.switchStatus(true);
      }
    },
    dragFinish: function (e) {
      if (this.disabled) {
        return;
      }

      if (this.isMoving && (this.bothWays || !this.sliderStatus)) {
        var _x = (e.pageX || e.changedTouches[0].pageX) - this.positionCache;
        if (_x < this.sliderWidth - this.handlerWidth) {
          this.positionCache = 0;
        }
        this.resetSlider();
      }
    },
    switchStatus: function (ok) {
      this.sliderStatus = ok;
      this.resetSlider();
    },
    resetSlider: function () {
      this.left = this.sliderStatus ? this.sliderWidth - this.handlerWidth : 0;
      this.isMoving = false;
    },
  },
  watch: {
    status: function (newValue) {
      this.sliderStatus = newValue;
      this.resetSlider();
    },
    /* 'status': function(newValue) {
      this.sliderStatus = newValue
      this.$forceUpdate();
    }, */
    sliderStatus: function (newValue, oldValue) {
      this.resetSlider();
      if (oldValue !== undefined && newValue != oldValue) {
        this.$emit("status-changed", newValue);
      }
    },
    proportions: {
      deep: true,
      handler() {
        this.resetSlider();
      },
    },
  },
};
</script>

<style>
.dvs_wrapper {
  @apply relative;
  @apply flex items-center justify-center overflow-hidden rounded-full transition duration-300;
}
.dvs_wrapper .dvs_slider {
  @apply relative h-full w-full rounded-full;
}
.dvs_wrapper .dvs_slider .dvs_handler {
  @apply absolute left-0 min-h-full cursor-move;
  @apply bg-navbar-active;
}
.dvs_wrapper.disabled .dvs_slider .dvs_handler {
  @apply bg-brand-dark;
}
.dvs_wrapper.active .dvs_slider .dvs_handler {
  @apply bg-brand-lightblue;
}

.dvs_wrapper .dvs_slider .dvs_label {
  @apply absolute top-0 bottom-0 flex w-full select-none items-center justify-center truncate text-center text-sm font-semibold;
  @apply text-brand-dark;
}
.dvs_wrapper.disabled .dvs_slider .dvs_label {
  @apply text-brand-darkblue;
}
.dvs_wrapper.active .dvs_slider .dvs_label {
  @apply text-white;
}
</style>

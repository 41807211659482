<template>
  <div class="flex flex-row space-x-2">
    <div class="flex items-center justify-center flex-shrink-0 w-5">
      <svg viewBox="0 0 16 18" class="self-start w-4 h-4 font-black text-center" :class="{
        'text-brand-darkblue': isCheckedIn || !eventIsPast,
        'text-gray-400': eventIsPast && !isCheckedIn,
      }">
        <g fill="none" fill-rule="evenodd" transform="translate(-4 -3)">
          <path fill="currentColor" fill-rule="nonzero"
            d="M18.4 4.636h-.8v-.818c0-.45-.36-.818-.8-.818-.44 0-.8.368-.8.818v.818H8v-.818C8 3.368 7.64 3 7.2 3c-.44 0-.8.368-.8.818v.818h-.8c-.88 0-1.6.737-1.6 1.637v13.09c0 .9.72 1.637 1.6 1.637h12.8c.88 0 1.6-.736 1.6-1.636V6.273c0-.9-.72-1.637-1.6-1.637zm-.8 14.728H6.4c-.44 0-.8-.369-.8-.819V8.727h12.8v9.818c0 .45-.36.819-.8.819z" />
        </g>
      </svg>
    </div>
    <div class="flex flex-col items-center flex-shrink-0 w-auto max-w-full">
      <span class="
          flex flex-col
          max-w-full
          text-sm
          font-bold
          leading-4
          truncate
          2xs:flex-row
        ">
        <time>{{ $d(new Date(dateFrom), "short") }}</time>
        <span v-if="dateUntil && dateFrom !== dateUntil">
          <span class="px-1 xs:inline-block">&ndash;</span>
          <time>{{ $d(new Date(dateUntil), "short") }}</time>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ItemDate",
  props: {
    dateUntil: {
      type: String,
    },
    dateFrom: {
      type: String,
    },
    eventIsPast: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="p-6 space-y-2">
    <div class="text-center select-none">
      <h3 class="text-xl font-bold text-white">
        {{ $t("event.mood-modal-checkin--title") }}
      </h3>
      <p class="text-sm">{{ $t("event.mood-modal-checkin--subtitle") }}</p>
    </div>
    <form class="space-y-4" role="form" v-on:submit.prevent="handleCheckin">
      <mood-selector :mood="mood" @update="updateMood" />
      <t-input
        v-model="comment"
        variant="dark"
        :placeholder="$t('event.form.placeholder--note-to-self')"
      />
      <t-button type="submit" variant="white">{{
        $t("event.form.btn--checkin-save")
      }}</t-button>
    </form>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import MoodSelector from "components/MoodSelector";

const EventRepository = Repository.get("my-events");

export default {
  name: "event-checkin-form",
  components: {
    MoodSelector
  },
  props: {
    event: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      mood: "neutral",
      comment: ""
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = false;
      this.mood = "neutral";
      this.comment = "";

      if (!this.event) {
        this.$emit("checkin-cancel");
      }
    },
    cancel() {
      this.$emit("checkin-cancel");
    },
    close() {
      if (this.loading) return;
      this.$emit("checkin-cancel");
    },
    closePositive() {
      this.$emit("checkin-success", this.event.uuid);
    },
    updateMood(mood) {
      this.mood = mood;
    },
    handleCheckin() {
      this.loading = true;

      EventRepository.checkIn(this.event, {
        mood: this.mood,
        note: this.comment
      })
        .then(r => {
          this.$emit("event-updated", r.data.event);
          this.closePositive();
        })
        .catch(error => {
          console.log("checkin failed:", error.response);
        })
        .then(() => {
          this.loading = false;
          this.close();
        });
    }
  }
};
</script>
